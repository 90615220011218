import React from "react";
import "../styles/common.scss";
import { Property } from "csstype";
import { CloseCircleOutlined } from "@ant-design/icons";
interface CommonLayoutProps {
  leftNodeClassName?: string;
  rightNodeClassName?: string;
  leftRender(): JSX.Element;
  rightRender(): JSX.Element;
}

/** 主页 */
export class CommonLayout extends React.Component<CommonLayoutProps> {
  /** 默认props */
  static defaultProps = {
    leftNodeClassName: "leftNode",
    rightNodeClassName: "rightNode",
  };
  render(): JSX.Element {
    return (
      <div className="responseLayout">
        <div>
          <div className={this.props.leftNodeClassName}>
            {this.props.leftRender()}
          </div>
          <div className={this.props.rightNodeClassName}>
            {this.props.rightRender()}
          </div>
        </div>
      </div>
    );
  }
}

interface ScrollLayoutProps {
  /** 渲染的data */
  data?: Array<Record<string, string | JSX.Element | number>>;
}
/** 滚动组件 */
export class ScrollLayout extends React.Component<ScrollLayoutProps> {
  constructor(props: ScrollLayoutProps) {
    super(props);
    this.firstDomRef = React.createRef();
    this.opacity = props.data?.map((v, i) => {
      if (i !== 0) {
        return 0;
      } else return 1;
    }) as Property.Opacity[];
  }
  position: Property.Position = "absolute";
  top: Property.Top = "4.94rem";
  opacity: Property.Opacity[] = [];
  firstDomRef: React.RefObject<HTMLDivElement>;
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }
  handleScroll(e: any) {
    if (this.firstDomRef.current === null) {
      return;
    }
    /**  获取从顶部到元素的距离  */
    const overOffset =
      e.srcElement.scrollingElement.scrollTop -
      (this.firstDomRef.current?.offsetTop ?? 2520);
    const length = this.props.data?.length ?? 4;
    /** 获取单个元素的高度 */
    const itemHeight = this.firstDomRef.current?.offsetHeight / length;
    /** 当当前元素距离小于0时，重置元素高度 */
    if (overOffset < 0) {
      this.position = "absolute";
      this.top = "4.94rem";
    } else if (overOffset > itemHeight * (length - 1)) {
      this.position = "absolute";
      this.top = `${itemHeight * (length - 1)}px`;
    } else {
      const index = Number((overOffset / itemHeight).toFixed(0));
      this.position = "fixed";
      this.top = "4.94rem";
      this.opacity = this.opacity.map((v, i) => {
        if (index === i) {
          return 1;
        } else return 0;
      });
    }
    this.forceUpdate();
  }

  render(): JSX.Element {
    return (
      <div
        className="Scroll"
        style={{ height: `calc(100vh * ${this.props.data?.length ?? 4})` }}
        ref={this.firstDomRef}
      >
        {this.props.data?.map((v, i) => (
          <div
            className="scrollContent"
            key={`scroll${i}`}
            style={{
              opacity: this.opacity[i],
              position: this.position,
              top: this.top,
            }}
          >
            <div className="scroll-left">
              <div
                style={{
                  fontFamily: "'HarmonyOS_light',Georgia, serif",
                  fontSize: "1rem",
                  fontWeight: 600,
                }}
              >
                {v.title}
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "200px",
                  height: 0,
                  borderBottom: "2px  solid gray",
                }}
              ></div>
              <div
                style={{
                  fontFamily: "'HarmonyOS_light', Georgia, serif",
                  fontSize: "1rem",
                  fontWeight: 500,
                  padding: "0.5rem 0",
                }}
              >
                {v.subTitle}
              </div>
              <div
                style={{
                  fontFamily: "'HarmonyOS_light', Georgia, serif",
                  fontSize: "0.7rem",
                  lineHeight: "1.5",
                }}
              >
                {v.des}
              </div>
            </div>
            <div className="scroll-Right">{v.icon}</div>
          </div>
        ))}
      </div>
    );
  }
}
interface VideoProps {
  src: string;
  closeMainVideo(): void;
}
export class VideoLayout extends React.Component<VideoProps> {
  render() {
    return (
      <div className="shadow">
        <div>
          <video autoPlay={true} controls={true} className="video">
            <source src={this.props.src} type="video/mp4" />
          </video>
          <CloseCircleOutlined
            className="closeIcon"
            onClick={this.props.closeMainVideo}
          />
        </div>
      </div>
    );
  }
}
