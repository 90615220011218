import { GET, POST } from "./api-request";
export interface UserForm {
  name: string;
  phone: string;
  email: string;
  sex: string;
}
/** 上传表单信息 */
export function uoLoadUserForm(data: UserForm): Promise<void> {
  return POST("/file/userForm", data);
}

export function getJobList(): Promise<any> {
  return GET("/job_list");
}
export function getCompanyList(): Promise<any> {
  return GET("/getCompanyHistory");
}
