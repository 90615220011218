import { Card, Col, Row, Input, message, Empty, Descriptions } from "antd";
import React from "react";
import join from "../styles/join.module.scss";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;
/** 加入我们 */
export default class JoinUs extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      //职位卡片
      CardMap: JSON.parse(JSON.stringify(this.TempData)),
      //加入我们文字
      joinUstext: {
        Title: "改变 — 就现在",
        Content: "你我山巅自相逢,归去人间烟火中",
      },
    };
  }
  /** 初始化 */
  componentDidMount() {}
  /**
   * 存放所有职位的对象
   */
  private TempData = [
    // {
    //   id: 10001,
    //   job_name: "项目经理",
    //   release_time: "2021-08-20",
    //   department: "项目部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10002,
    //   job_name: "技术人员（设计、监理、技术负责人）",
    //   release_time: "2021-08-20",
    //   department: "项目部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10003,
    //   job_name: "造价人员（审计、成控）",
    //   release_time: "2021-08-20",
    //   department: "项目部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10004,
    //   job_name: "综合后勤（施工测量员、质检资料员、安全员）",
    //   release_time: "2021-08-20",
    //   department: "项目部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10005,
    //   job_name: "财务总监",
    //   release_time: "2021-08-20",
    //   department: "财务部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10006,
    //   job_name: "法务专员",
    //   release_time: "2021-08-20",
    //   department: "法务部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10007,
    //   job_name: "行政总监",
    //   release_time: "2021-08-20",
    //   department: "行政部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10008,
    //   job_name: "人力资源总监",
    //   release_time: "2021-08-20",
    //   department: "人事部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10009,
    //   job_name: "运营总监",
    //   release_time: "2021-08-20",
    //   department: "市场部",
    //   location: "四川·雅安市",
    //   team_type: "manage",
    // },
    // {
    //   id: 10010,
    //   job_name: "首席财务执行官(CFO)",
    //   release_time: "2021-08-20",
    //   department: "财务部",
    //   location: "四川·雅安市",
    //   team_type: "capital",
    // },
    // {
    //   id: 10011,
    //   job_name: "投资团队",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "capital",
    // },
    // {
    //   id: 10012,
    //   job_name: "政府资源团队",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "netWork",
    // },
    // {
    //   id: 10013,
    //   job_name: "管理资源团队",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "netWork",
    // },
    // {
    //   id: 10014,
    //   job_name: "市场资源团队",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "netWork",
    // },
    // {
    //   id: 10015,
    //   job_name: "娱乐餐饮资源团队",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "other",
    // },
    // {
    //   id: 10016,
    //   job_name: "增长资源团队",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "other",
    // },
    // {
    //   id: 10017,
    //   job_name: "建筑材料市场信息收集员",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "info",
    // },
    // {
    //   id: 10018,
    //   job_name: "建筑租赁市场信息收集员",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "info",
    // },
    // {
    //   id: 10019,
    //   job_name: "建筑劳务市场信息收集员",
    //   release_time: "2021-08-20",
    //   department: "-",
    //   location: "四川·雅安市",
    //   team_type: "info",
    // },
  ];
  /**
   * 搜索方法
   * @param {string} value 搜索所需字符串
   * @param {any} e 触发事件
   */
  onSearch(value: string, e: any): void {
    let searchMap = this.TempData.filter(
      (item: any) =>
        item.job_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    if (searchMap.length > 0) {
      this.setState({ CardMap: searchMap });
    } else {
      this.setState({ CardMap: [] });
      message.warning("没有搜索到相关的内容哦！");
    }
  }
  /**
   * 查询无数据时调用
   * @returns {JSX.Element} 返回暂无数据
   */
  searchError(): JSX.Element {
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        className={join.search_error}
        description="啊哦！暂时没有找到数据哦！"
      />
    );
  }
  /**
   * 卡片被单机触发事件，会携带id跳转到新页面
   * @param id 点击卡片id
   */
  seeWorkDetail(id: number): void {
    this.props.history.push(`/applyWork?id=${id}`);
  }
  /**
   * 渲染所有职位方法
   * @returns {JSX.Element} 所有职位列表
   */
  renderCard(): JSX.Element {
    return (this.state as { CardMap: any }).CardMap.map(
      (v: any, i: number): JSX.Element => (
        <Col
          key={`cardMap_${i}`}
          xxl={8}
          xl={8}
          lg={8}
          md={24}
          sm={24}
          xs={24}
          style={{ marginBottom: "1.38rem" }}
        >
          <Card
            className={join.Cards}
            onClick={() => {
              this.seeWorkDetail(v.id);
            }}
          >
            <Descriptions column={1}>
              <div
                slot="title"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                {v.job_name}
              </div>
              <Descriptions.Item label="工作地点">
                {v.location}
              </Descriptions.Item>
              <Descriptions.Item label="岗位部门">
                {v.department}
              </Descriptions.Item>
              <Descriptions.Item label="发布时间">
                {v.release_time}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      )
    );
  }
  render(): JSX.Element {
    return (
      <div id={join.join}>
        <div className={join.title_Background}>
          <div className={join.title}>
            <div style={{ lineHeight: "1.1" }}>
              {this.state.joinUstext.Title}
            </div>
            <span style={{ fontSize: "16px", letterSpacing: "2px" }}>
              {this.state.joinUstext.Content}
            </span>
            <div className={join.title_line}></div>
          </div>
        </div>

        <div className={join.main}>
          <div className={join.head_wraps}>
            <span>加入我们</span>
            <hr style={{ width: "3.6rem" }} />
            <Search
              size="middle"
              placeholder="输入要搜索的职位"
              prefix={<SearchOutlined />}
              onSearch={(value: string, e: any) => this.onSearch(value, e)}
              enterButton="搜索职位"
              className={join.search}
            />
            <Row style={{ width: "100%" }} gutter={36}>
              {(this.state as { CardMap: any }).CardMap.length > 0
                ? this.renderCard()
                : this.searchError()}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
