import React from "react";
import style from "../styles/message.module.scss";
import { Button, Timeline } from "antd";
import { ReactComponent as Logo1 } from "../asset/images/honor.svg";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";

/** 主页 */
export default class HomePage extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      dataSource: [
        {
          title: "公司开业了！",
          release_time: "2019-09-25",
          src: <Logo1 />,

          description:
            "2019年9月25日是一个激动人心的时刻，雅安市紫木工程咨询有限公司在今天成立啦！",
          action: {
            play: {
              source: "",
            },
          },
        },
      ],
    };
  }
  /** 初始化 */
  componentDidMount() {}
  render(): JSX.Element {
    return (
      <>
        <div id={style.Message}>
          <div className={style.content}>
            拨开乌云 可见山海 难行乐途 唯听心声
          </div>
        </div>
        <div className={style.List}>
          <Timeline mode="alternate">
            {(this.state as any).dataSource.map((item: any, i: number) => (
              <Timeline.Item key={i}>
                <span slot="dot">
                  <ClockCircleOutlined
                    style={{ color: "red", marginRight: "5px" }}
                  ></ClockCircleOutlined>
                  {item.release_time}
                </span>
                <div className={style.listItem}>
                  <div className={style.listItem_img}>
                    <Logo1 />
                  </div>
                  <div>
                    <div className={style.listItem_title}>{item.title}</div>
                    <div>{item.description}</div>
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
          <Button style={{ marginTop: 16 }}>了解更多我们</Button>
        </div>
      </>
    );
  }
}
