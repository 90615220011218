import { CloseOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Layout, List, Menu, Row } from "antd";
import React from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import CityPartner from "./components/city-partner";
import HomePage from "./components/homepage";
import JoinUs from "./components/join-us";
import style from "./styles/app.module.scss";
import Message from "./components/message";

import AppWork from "./components/applyWork";
/** 首页Header */
const Header = (props: any) => {
  switch (props.type) {
    case "mobile":
      return (
        <>
          {props.visible ? (
            <CloseOutlined
              onClick={props.onClose}
              style={{
                fontSize: "1.2rem",
                position: "absolute",
                left: "0.8rem",
              }}
            />
          ) : (
            <MenuUnfoldOutlined
              style={{
                fontSize: "1.2rem",
                position: "absolute",
                left: "0.8rem",
              }}
              onClick={props.showDrawer}
            />
          )}
          <div className={style.logo}>
            <img
              src="/logo/logoinfo.svg"
              alt="未找到"
              style={{ height: "100%", width: "100%" }}
            ></img>
          </div>
        </>
      );
    default:
      return (
        <>
          <div className={style.logo}>
            <img
              src="/logo/logoinfo.svg"
              alt="未找到"
              style={{ height: "100%", width: "100%" }}
            ></img>
          </div>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[props.defaultSelectedKeys]}
            onClick={(e) => props.handlerClick(e)}
            style={{
              paddingLeft: "44px",
              border: "unset",
              fontSize: "0.65rem",
            }}
          >
            {props.dataSource.map((v: { name: string }) => {
              return <Menu.Item key={v.name}>{v.name}</Menu.Item>;
            })}
          </Menu>
        </>
      );
  }
};
/** 通用的footer */
const CommonFooter = () => (
  <>
    <div className={style.footer}>
      <div>
        <Row gutter={16}>
          <Col className="gutter-row" xxl={4} xl={4} lg={4} md={24}>
            <div>
              联系
              <br />
              <span>contact</span>
            </div>
          </Col>
          <Col className="gutter-row" xxl={14} xl={14} lg={14} md={24}>
            <div>
              <Descriptions
                title="联系我们"
                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="电话">18180001725</Descriptions.Item>
                <Descriptions.Item label="邮编">625000</Descriptions.Item>
                <Descriptions.Item label="地址">
                  四川省雅安市雨城区羌江南路345号
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
          <Col className="gutter-row" xxl={6} xl={6} lg={6} md={24}>
            <div></div>
          </Col>
        </Row>
      </div>
    </div>
    <div className={style.footerBar}>
      <span>
        <Button
          style={{
            color: "white",
          }}
          onClick={() => {
            window.open(
              "https://beian.miit.gov.cn/publish/query/indexFirst.action"
            );
          }}
          type="link"
        >
          蜀ICP备2023033965号
        </Button>
        | Copyright © 2019 - 2024 雅安紫木工程咨询有限公司 | Design By Alephant
      </span>
    </div>
  </>
);
type state = { currentSelectMenuKey: string; type: string; visible: boolean };
/** 首页 */
class App extends React.Component<RouteComponentProps & {}, state> {
  constructor(props: RouteComponentProps & {}) {
    super(props);
    const url = props.location.pathname;

    const currentSelectMenuKey =
      App.menuList.find((v) => v.path === url)?.name ?? "首页";

    this.state = {
      currentSelectMenuKey: currentSelectMenuKey,
      type: "pc",
      visible: false,
    };
  }
  static menuList = [
    {
      name: "首页",
      path: "/",
    },
    // {
    //   name: "城市合伙人",
    //   path: "/city-partner",
    // },
    {
      name: "加入我们",
      path: "/joinUs",
    },
    {
      name: "关于我们",
      path: "/Information",
    },
  ];
  /** 显示抽屉 */
  showDrawer() {
    this.setState({ visible: true });
  }
  /** 关闭抽屉 */
  onclose() {
    this.setState({ visible: false });
  }
  /** 监听offsetWidth，更新模式 */
  resize() {
    const offsetWith = document.body.offsetWidth;
    if (offsetWith < 700) {
      document.documentElement.setAttribute("type", "mobile");
      this.setState({ type: "mobile" });
    } else {
      document.documentElement.setAttribute("type", "pc");
      this.setState({ type: "pc" });
    }
  }
  /** 增加事件监听者 */
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  /** 移除事件监听者 */
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  /** 设置选中的Key，并跳转 */
  setSelectKey(item: { key: string }) {
    const list = App.menuList.find((v) => v.name === item.key);
    this.setState({ currentSelectMenuKey: list?.name ?? "首页" });
    this.props.history.push(list?.path ?? "/");
  }
  /** 重置滚动条 */
  componentDidUpdate(prevProps: RouteComponentProps & {}) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/applyWork" component={() => <AppWork />}></Route>

        <Route exact>
          <Layout>
            <Layout.Header id={style.header}>
              <Header
                visible={(this.state as any).visible}
                defaultSelectedKeys={(this.state as any).currentSelectMenuKey}
                type={(this.state as any).type}
                onClose={this.onclose.bind(this)}
                showDrawer={this.showDrawer.bind(this)}
                handlerClick={(item: any) => this.setSelectKey(item)}
                dataSource={App.menuList}
              />
            </Layout.Header>
            <div className={style.headerPlaceholder}></div>
            <Layout.Content>
              {(this.state as any).visible ? (
                <div className={`${style.shadowMenu} ${style.show}`}>
                  <List
                    bordered={false}
                    dataSource={App.menuList}
                    renderItem={(item) => (
                      <List.Item
                        style={{ padding: "1rem 1rem" }}
                        onClick={() => this.onclose()}
                      >
                        <Button type="link" href={item.path}>
                          {item.name}
                        </Button>
                        {/* <Link to={item.path}>{item.name}</Link> */}
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                <div className={`${style.shadowMenu}`}></div>
              )}
              <Switch>
                <Route path="/joinUs" exact component={() => <JoinUs />} />
                <Route
                  path="/city-partner"
                  exact
                  component={() => <CityPartner />}
                />
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <HomePage {...props} type={(this.state as any).type} />
                  )}
                />
                <Route
                  path="/information"
                  exact
                  render={(props) => <Message {...props} />}
                />
              </Switch>
            </Layout.Content>
            <Layout.Footer>
              <CommonFooter></CommonFooter>
            </Layout.Footer>
          </Layout>
        </Route>
      </Switch>
    );
  }
}

const newApp = withRouter(App as any);
export default newApp;
