import React from "react";
import style from "../styles/applyWork.module.scss";
import { Button, Modal, Upload } from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";
import { baseUrl } from "../api/api-request";
import { getJobList } from "../api/fileUpload";
/** 职位详情界面 */
export default class applyWork extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      //主页面传递过来的值
      id: new URL(document.location.href).searchParams.get("id"),
      isModalVisible: false,
      WorkDetails: {
        id: "3d229f6a-05de-4897-9b39-f32ddb873a44",
        job_name: "XXX",
        release_time: "Wed, 21 Oct 2015 18:27:50 GMT",
        team_type: "1",
        department: "研发部",
        location: "四川省雅安市",
        description:
          "负责项目全过程规范、技术层面管理，负责审查并提出施工图纸所有技术性问题，找到最优解决方案。 完成项目所有技术性文件的修改或编写、审查，协助项目负责人完成项目统筹管理、现场管理目标考核任务。欢迎您的加入！",
      },
    };
  }
  /** 初始化 */
  componentDidMount() {
    getJobList().then((res) => {
      const data = res.data.find((x: any) => x.id === this.state.id);
      this.setState({ WorkDetails: data });
    });
  }
  UploadProps = {
    name: "file",
    multiple: false,
    accept: ".pdf, .md, .txt , .doc",
    action: `${baseUrl}/file/Resume`,
    onChange(info: any) {
      const { status } = info.file;
      console.log(info.file, info.fileList);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
    },
    data: {
      id: new URL(document.location.href).searchParams.get("id"),
    },
  };

  render(): JSX.Element {
    return (
      <div id={style.applyWork}>
        <div className={style.headers}>
          <a href="/joinUs" className={style.go_back}>
            <CaretLeftOutlined />
            返回资源整合页面
          </a>
        </div>
        <Modal
          title="请上传您的简历"
          visible={this.state.isModalVisible}
          okText="提交"
          cancelText="取消"
          // onOk={handleOk}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Upload.Dragger {...this.UploadProps}>
            <p className="ant-upload-text">点击或者拖动文件上传</p>
            <p className="ant-upload-hint">
              支持单个或批量上传格式为.pdf, .md, .txt , .doc 的文件
            </p>
          </Upload.Dragger>
          ,
        </Modal>
        <div className={style.content}>
          <div className={style.card}>
            <Button
              className={style.btn}
              onClick={() => {
                this.setState({ isModalVisible: true });
              }}
            >
              申请职位
            </Button>
            <div className={style.job_info}>
              <div className={style.job_info_top}>
                <span>发布时间：{this.state.WorkDetails.release_time}</span>
              </div>
            </div>
            <div style={{ fontSize: "0.8rem" }}>
              {this.state.WorkDetails.job_name}
            </div>
            <div className={style.job_status}>
              <span className={style.status_item}>Alephant</span>
              <span className={style.status_item}>
                {this.state.WorkDetails.department}
              </span>
            </div>
            <div className={style.workAddress}>
              <span>四川省·雅安市·雨城区</span>
            </div>

            <div className={style.workContent}>
              <div className={style.workContentTitle}>
                职位描述
                <div className={style.hr}></div>
              </div>
              <div>{this.state.WorkDetails.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
