import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
export const baseUrl = "http://127.0.0.1:3202";
/** 创建axios实例 */
const service = axios.create({});
/**
 *
 * error callback ,get token to user center
 * @param { AxiosError } error sead
 * @returns { Promise.reject } 返回Promise.REJECT 错误内容
 * */
const err = (error: AxiosError) => {
  if (error.response) {
    /** 此处调用错误处理机制 */
  }
  return Promise.reject(error);
};

/** add request  interceptors
 *
 * add token to request header
 * if missing token ,call  callback function
 *
 * @author Alephant
 *
 */
service.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
}, err);

/**
 * add response  interceptors
 * @author Alephant
 */
service.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, err);

/**
 * 通用post方法
 *
 *  @param { string } url 路由路径
 *  @param {Record<string, unknown>} body 请求体
 *  @param {Record<string, unknown>} params 路由参数
 *  @param  {AxiosRequestConfig} config 路由配置
 *  @param  {string} newBaseUrl 新的路径
 *  @returns { Promise } 返回一个promise
 *  @author Alephant
 */
export const POST = <T>(
  url: string,
  body: object,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig,
  newBaseUrl?: string
): Promise<T> => {
  return service
    .post<T>(`${newBaseUrl ?? baseUrl}${url}`, body, {
      params,
      ...(config ?? {}),
    })
    .then((res) => res.data);
};

/**
 * 通用post方法
 *
 *  @param { string } url 路由路径
 *  @param {Record<string, unknown>} params 路由参数
 *  @param  {AxiosRequestConfig} config 路由配置
 *  @param  {string} newBaseUrl 新的路径
 *  @param   {boolean} headers 添加是否获取header
 *  @returns { Promise<AxiosResponse<T> | T> } 返回一个promise
 *  @author Alephant
 */
export const GET = <T>(
  url: string,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig,
  newBaseUrl?: string,
  headers?: boolean
): Promise<AxiosResponse<T> | T> => {
  return service
    .get<T>(`${newBaseUrl ?? baseUrl}${url}`, {
      params,
      ...(config ?? {}),
    })
    .then(
      (res) => {
        if (headers) {
          return res;
        } else {
          return res.data;
        }
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
};
/**
 * 通用put方法
 *
 *  @param { string } url 路由路径
 *  @param {Record<string, unknown>} body 请求体
 *  @param {Record<string, unknown>} params 路由参数
 *  @param  {AxiosRequestConfig} config 路由配置
 *  @param  {string} newBaseUrl 新的路径
 *  @returns { Promise } 返回一个promise
 *  @author Alephant
 */
export const PUT = <T>(
  url: string,
  body: Record<string, unknown>,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig,
  newBaseUrl?: string
): Promise<AxiosResponse<T>> => {
  return service
    .put<T>(`${newBaseUrl ?? baseUrl}${url}`, body, {
      params,
      ...(config ?? {}),
    })
    .then(
      (res) => res,
      (reason) => {
        return Promise.reject(reason);
      }
    );
};

/**
 * 通用delete方法
 *  @param { string } url 路由路径
 *  @param {Record<string, unknown>} body 请求体
 *  @param {Record<string, unknown>} params 路由参数
 *  @param  {AxiosRequestConfig} config 路由配置
 *  @param  {string} newBaseUrl 新的路径
 *  @returns { Promise } 返回一个promise
 *  @author Alephant
 */
export const DELETE = <T>(
  url: string,
  body?: Record<string, unknown>,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig,
  newBaseUrl?: string
): Promise<AxiosResponse<T>> => {
  return service
    .delete<T>(`${newBaseUrl ?? baseUrl}${url}`, {
      data: body,
      params,
      ...(config ?? {}),
    })
    .then(
      (res) => res,
      (reason) => {
        return Promise.reject(reason);
      }
    );
};
/**
 * 通用patch方法
 *  @param { string } url 路由路径
 *  @param {Record<string, unknown>} body 请求体
 *  @param {Record<string, unknown>} params 路由参数
 *  @param  {AxiosRequestConfig} config 路由配置
 *  @param  {string} newBaseUrl 新的路径
 *  @returns { Promise } 返回一个promise
 *  @author Alephant
 */
export const PATCH = <T>(
  url: string,
  body: Record<string, unknown>,
  params?: Record<string, unknown>,
  config?: AxiosRequestConfig,
  newBaseUrl?: string
): Promise<AxiosResponse<T>> => {
  return service
    .patch<T>(`${newBaseUrl ?? baseUrl}${url}`, body, {
      params,
      ...(config ?? {}),
    })
    .then(
      (res) => res,
      (reason) => {
        return Promise.reject(reason);
      }
    );
};
